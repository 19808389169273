import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import * as $ from 'jquery';
import * as AOS from 'aos';
import * as SmoothScroll from 'smooth-scroll';

AOS.init();

$(document).scroll(function() {
  const offset = $(this).scrollTop();
  if (offset > 150) {
    $('.navigation-section').addClass('navigation-section-solid-background');
  }
  if (offset < 150) {
    $('.navigation-section').removeClass('navigation-section-solid-background');
  }
});


const smoothScroll = new SmoothScroll('a[href*="#"]', {
  offset: 90
});

// uses on instead of bind, so it works with 1.9.1/2.0 +
// tslint:disable-next-line:only-arrow-functions
$(document).on('scroll', function(e) {

  const sections = $('section');

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < sections.length; i++) {

    const section = $(sections[i]);

    if (section.offset().top < window.pageYOffset + 95
      && section.offset().top +
      section.height() > window.pageYOffset + 95
    ) {
      const id = section.data('id');

      if (id) {
        window.location.hash = id;
        return;
      }
    }
  }
});

// tslint:disable-next-line:only-arrow-functions
$(window).on('hashchange', function(e) {

  const url = window.location.href;
  const hash = url.split('#')[1];

  if (!hash) {
    return;
  }

  $('.navigation-list-item').each(function() {

    const dataId = $(this).data('id');

    if (dataId && dataId === hash) {
      $(this).addClass('navigation-list-item-marked-up');
    } else {
      $(this).removeClass('navigation-list-item-marked-up');
    }
  });
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
