import {TranslationKeys} from './translation-keys';

export class SupportedLanguageEntry {

  public translationKey: string;
  public languageKey: string;
  public displayString: string;

  constructor(translationKey: string, languageKey: string, displayString: string) {
    this.translationKey = translationKey;
    this.languageKey = languageKey;
    this.displayString = displayString;
  }

  public static  getSupportedLanguageEntries(): SupportedLanguageEntry[] {
    return [
      new SupportedLanguageEntry(TranslationKeys.language.english, 'en', 'EN'),
      new SupportedLanguageEntry(TranslationKeys.language.german, 'de', 'DE'),
      new SupportedLanguageEntry(TranslationKeys.language.french, 'fr', 'FR'),
    ];
  }
}
