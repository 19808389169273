import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SupportedLanguageEntry} from '../shared/supported-language-entry';
import {TranslationKeys} from '../shared/translation-keys';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private readonly LANGUAGE_STORAGE_NAME = 'language';

  constructor(private translateService: TranslateService) {
    this.configureNgxTranslate();
  }

  configureNgxTranslate() {
    this.translateService.setDefaultLang('en');
    const lang = this.evaluateLang();
    this.translateService.use(lang);
  }

  evaluateLang() {

    const value = localStorage.getItem(this.LANGUAGE_STORAGE_NAME);

    if (value) {
      return value;
    }

    const language = navigator.language || (navigator.languages || ['en']) [0];
    return language.split('-')[0];
  }

  public changeLanguage(supportedLanguageEntry: SupportedLanguageEntry) {

    localStorage.setItem(this.LANGUAGE_STORAGE_NAME, supportedLanguageEntry.languageKey);

    this.translateService.use(supportedLanguageEntry.languageKey);
  }
}
