<section class="navigation-section">
  <ul class="social-media-list">
    <li *ngFor="let supportedLanguageEntry of supportedLanguageEntries">
      <a [ngClass]="{'language-highlight': translateService.currentLang === supportedLanguageEntry.languageKey}" (click)="changeLanguage(supportedLanguageEntry)">{{supportedLanguageEntry.displayString}}</a>
    </li>
  </ul>

  <ul class="navigation-list">
    <li data-id="home" class="navigation-list-item navigation-list-item-marked-up">
      <a href="#title-section" translate>{{TranslationKeys.navigation.home}}</a>
    </li>
    <li data-id="preise" class="navigation-list-item">
      <a href="#preis-section" translate>{{TranslationKeys.navigation.prices}}</a>
    </li>
    <li data-id="kontakt" class="navigation-list-item">
      <a href="#kontakt-section" translate>{{TranslationKeys.navigation.contact}}</a>
    </li>
    <li class="navigation-burger-button" onclick="toggleMobileNavigationMenu()">
      <a>
        <fa-icon class="white-opacity" [icon]="faBars"></fa-icon>
      </a>
    </li>
  </ul>

  <ul class="navigation-list-for-mobile">
    <li data-id="home" class="navigation-list-item navigation-list-item-marked-up">
      <a href="#title-section" onclick="toggleMobileNavigationMenu()" translate>{{TranslationKeys.navigation.home}}</a>
    </li>
    <li data-id="preise" class="navigation-list-item">
      <a href="#preis-section" onclick="toggleMobileNavigationMenu()"
         translate>{{TranslationKeys.navigation.prices}}</a>
    </li>
    <li data-id="kontakt" class="navigation-list-item">
      <a href="#kontakt-section" onclick="toggleMobileNavigationMenu()"
         translate>{{TranslationKeys.navigation.contact}}</a>
    </li>
  </ul>
</section>


<section id="title-section" data-id="home" class="title-section">
  <div class="title-container">
    <img data-aos="fade" data-aos-delay="500" src="assets/pics/logo/logo.svg" alt="">
  </div>
  <div class="header-image-container">
    <div class="black-foil"></div>

    <img class="header-image" src="assets/pics/hannah-querformat-lower.jpg" alt="Profilbild Hannah Dobozy">

    <h1 style="display: none" translate>{{TranslationKeys.title.heading1}}</h1>
    <h2 style="display: none" translate>{{TranslationKeys.title.heading2}}</h2>
  </div>

  <div class="header-down-arrow-container">
    <div class="header-down-arrow-bottom-row">
      <a href="#leistung-section">
        <fa-icon class="fa-2x white-opacity" [icon]="faChevronDown"></fa-icon>
      </a>
    </div>
  </div>
</section>

<section id="leistung-section" data-id="leistungen" class="cards-section white-design">
  <h2 translate>{{TranslationKeys.leistungen.heading}}</h2>

  <div class="cards-container" data-aos="fade">
    <div class="card card-4">
      <img src="assets/pics/icons/globe.svg" alt="">
      <h3 translate>{{TranslationKeys.leistungen.translations.heading}}</h3>
      <p translate>{{TranslationKeys.leistungen.translations.description}}</p>
      <a href="#fachgebiete-section">
        <span translate>{{TranslationKeys.leistungen.moreInformation}}</span>
      </a>
    </div>
    <div class="card card-4">
      <img src="assets/pics/icons/glasses.svg" alt="">
      <h3 translate>{{TranslationKeys.leistungen.proofreading.heading}}</h3>
      <p translate>{{TranslationKeys.leistungen.proofreading.description}}</p>
      <a href="#korrekturlesen-section">
        <span translate>{{TranslationKeys.leistungen.moreInformation}}</span>
      </a>
    </div>
    <div class="card card-4">
      <img src="assets/pics/icons/piggybank.svg" alt="">
      <h3 translate>{{TranslationKeys.leistungen.individualOffers.heading}}</h3>
      <p translate>{{TranslationKeys.leistungen.individualOffers.description}}</p>
      <a href="#preis-section">
        <span translate>{{TranslationKeys.leistungen.moreInformation}}</span>
      </a>
    </div>
    <div class="card card-4">
      <img src="assets/pics/icons/science.svg" alt="">
      <h3 translate>{{TranslationKeys.leistungen.branches.heading}}</h3>
      <p translate>{{TranslationKeys.leistungen.branches.description}}</p>
      <a href="#fachgebiete-section">
        <span translate>{{TranslationKeys.leistungen.moreInformation}}</span>
      </a>
    </div>
  </div>
</section>


<section id="korrekturlesen-section" data-id="korrekturlesen" class="cards-section dark-design">

  <h2 translate>{{TranslationKeys.korrekturlesen.heading}}</h2>
  <p translate>{{TranslationKeys.korrekturlesen.description}}</p>

  <div class="cards-container">
    <div class="card card-2">
      <h3 translate>{{TranslationKeys.korrekturlesen.korrektorat.heading}}</h3>
      <p translate *ngIf="translateService.currentLang !== 'en'">{{TranslationKeys.korrekturlesen.korrektorat.description}}</p>

      <ul>
        <li translate>{{TranslationKeys.korrekturlesen.korrektorat.list.one}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.korrektorat.list.two}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.korrektorat.list.three}}</li>
      </ul>
    </div>
    <div class="card card-2">
      <h3 translate>{{TranslationKeys.korrekturlesen.lektorat.heading}}</h3>
      <p translate *ngIf="translateService.currentLang !== 'en'">{{TranslationKeys.korrekturlesen.lektorat.description}}</p>

      <ul>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.one}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.two}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.three}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.four}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.five}}</li>
        <li translate>{{TranslationKeys.korrekturlesen.lektorat.list.six}}</li>
      </ul>
    </div>
  </div>
</section>


<section id="fachgebiete-section" data-id="fachgebiete" class="cards-section white-design">
  <h2 style="margin-bottom:70px" translate>{{TranslationKeys.fachgebiete.heading}}</h2>
  <p style="margin-bottom: 80px" translate>{{TranslationKeys.fachgebiete.description}}</p>

  <div class="cards-container" data-aos="fade">
    <div class="card card-3">
      <img src="assets/pics/icons/cart.svg" alt="">
      <h3 translate>{{TranslationKeys.fachgebiete.eCommerce.heading}}</h3>
      <p translate>{{TranslationKeys.fachgebiete.eCommerce.paragraph1}}</p>
      <p translate>{{TranslationKeys.fachgebiete.eCommerce.paragraph2}}</p>
    </div>
    <div class="card card-3">
      <img src="assets/pics/icons/computer.svg" alt="">
      <h3 translate>{{TranslationKeys.fachgebiete.IT.heading}}</h3>
      <p translate>{{TranslationKeys.fachgebiete.IT.paragraph1}}</p>
      <p translate>{{TranslationKeys.fachgebiete.IT.paragraph2}}</p>
    </div>
    <div class="card card-3">
      <img src="assets/pics/icons/note.svg" alt="">
      <h3 translate>{{TranslationKeys.fachgebiete.music.heading}}</h3>
      <p translate>{{TranslationKeys.fachgebiete.music.paragraph1}}</p>
      <p translate>{{TranslationKeys.fachgebiete.music.paragraph2}}</p>
    </div>
  </div>
</section>


<section id="preis-section" data-id="preise" class="cards-section dark-design">

  <h2 translate>{{TranslationKeys.preisgestaltung.heading}}</h2>

  <div class="cards-container">
    <div class="card card-2">
      <h3 translate>{{TranslationKeys.preisgestaltung.translations.heading}}</h3>
      <p translate>{{TranslationKeys.preisgestaltung.translations.description}}</p>

      <ul>
        <li translate>{{TranslationKeys.preisgestaltung.translations.list.one}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.translations.list.two}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.translations.list.three}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.translations.list.four}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.translations.list.five}}</li>
      </ul>
    </div>
    <div class="card card-2">
      <h3 translate>{{TranslationKeys.preisgestaltung.lektorat.heading}}</h3>
      <p translate>{{TranslationKeys.preisgestaltung.lektorat.description}}</p>

      <ul>
        <li translate>{{TranslationKeys.preisgestaltung.lektorat.list.one}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.lektorat.list.two}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.lektorat.list.three}}</li>
        <li translate>{{TranslationKeys.preisgestaltung.lektorat.list.four}}</li>
      </ul>
    </div>
  </div>
</section>


<section id="kontakt-section" data-id="kontakt" class="cards-section white-design">
  <h2 translate>{{TranslationKeys.kontakt.heading}}</h2>

  <div class="cards-container">
    <div class="card card-2">
      <img src="assets/pics/icons/telephone.svg" alt="">
      <h3 translate>{{TranslationKeys.kontakt.telephone.heading}}</h3>
      <p translate>{{TranslationKeys.kontakt.telephone.description}}</p>
      <a class="telephone" href="tel:+49 174 / 18 92 928">+49 174 / 18 92 928</a>
    </div>
    <div class="card card-2">
      <img src="assets/pics/icons/mail.svg" alt="" style="width: 110px; padding: 7px;">
      <h3 translate>{{TranslationKeys.kontakt.email.heading}}</h3>
      <p translate>{{TranslationKeys.kontakt.email.description}}</p>
      <a class="mail" href="mailto:kontakt@dobozy-uebersetzungen.de">kontakt@dobozy-uebersetzungen.de</a>
    </div>
  </div>
</section>

<section id="about-me-section" data-id="about-me" class="text-and-picture-section dark-design">

  <div class="text-column">
    <h2 translate>{{TranslationKeys.ueberMich.heading}}</h2>

    <p class="p-left" translate>{{TranslationKeys.ueberMich.paragraph1}}</p>
    <p class="p-right">
      <span translate>{{TranslationKeys.ueberMich.paragraph2.beforeInterculturalLink}}</span>
      <a href="https://www.intercultural-elements.de/" target="_blank">Intercultural Elements</a>
      <span translate>{{TranslationKeys.ueberMich.paragraph2.afterInterculturalLink}}</span>
      <a href="https://www.ipoque.com/" target="_blank">Rohde&nbsp;&&nbsp;Schwarz Cybersecurity GmbH</a>
      <span translate>{{TranslationKeys.ueberMich.paragraph2.afterRohdeLink}}</span>
    </p>
    <p class="p-left" translate>{{TranslationKeys.ueberMich.paragraph3}}</p>
  </div>
  <div class="image-column">
    <img style="filter: saturate(0.9)" src="assets/pics/hannah-hochformat-lower.jpg" alt="Hannah Dobozy Hochformat">
  </div>
</section>


<section class="copyright-section white-design">
  <div class="social-media-footer-container">
    <a href="https://www.xing.com/profile/Hannah_Dobozy/cv" target="_blank">
      <fa-icon class="fa-2x" [icon]="faXing"></fa-icon>
    </a>
    <a href="https://www.linkedin.com/in/hannah-dobozy-91699918a/" target="_blank">
      <fa-icon class="fa-2x" [icon]="faLinkedin"></fa-icon>
    </a>
  </div>

  <div class="contact-footer-container">

    <table>
      <tr>
        <td>Telefon:</td>
        <td><a href="tel:+491715106662">+49 171 / 510 66 62</a></td>
      </tr>
      <tr>
        <td>E-Mail:</td>
        <td><a href="mailto:kontakt@dobozy-uebersetzungen.de">kontakt@dobozy-uebersetzungen.de</a></td>
      </tr>
    </table>
  </div>

  <div>
    <p>
      <a routerLink="/impressum"
         routerLinkActive="active">
        {{TranslationKeys.footer.impressum | translate}}
      </a>
    </p>

    <p>
      <a routerLink="/datenschutz"
         routerLinkActive="active">
        {{TranslationKeys.footer.datenschutz | translate}}
      </a>
    </p>
  </div>
</section>
