export class TranslationKeys {

  public static readonly navigation = {
    home: TranslationKeys._t('navigation.home'),
    prices: TranslationKeys._t('navigation.prices'),
    contact: TranslationKeys._t('navigation.contact')
  };

  public static readonly title = {
    heading1: TranslationKeys._t('title.heading1'),
    heading2: TranslationKeys._t('title.heading2')
  };

  public static readonly leistungen = {
    heading: TranslationKeys._t('leistungen.heading'),
    moreInformation: TranslationKeys._t('leistungen.more-information'),
    translations: {
      heading: TranslationKeys._t('leistungen.translations.heading'),
      description: TranslationKeys._t('leistungen.translations.description'),
    },
    proofreading: {
      heading: TranslationKeys._t('leistungen.proofreading.heading'),
      description: TranslationKeys._t('leistungen.proofreading.description'),
    },
    individualOffers: {
      heading: TranslationKeys._t('leistungen.individual-offers.heading'),
      description: TranslationKeys._t('leistungen.individual-offers.description'),
    },
    branches: {
      heading: TranslationKeys._t('leistungen.branches.heading'),
      description: TranslationKeys._t('leistungen.branches.description'),
    }
  };


  public static readonly korrekturlesen = {
    heading: TranslationKeys._t('korrekturlesen.heading'),
    description: TranslationKeys._t('korrekturlesen.description'),
    korrektorat: {
      heading: TranslationKeys._t('korrekturlesen.korrektorat.heading'),
      description: TranslationKeys._t('korrekturlesen.korrektorat.description'),
      list: {
        one: TranslationKeys._t('korrekturlesen.korrektorat.list.1'),
        two: TranslationKeys._t('korrekturlesen.korrektorat.list.2'),
        three: TranslationKeys._t('korrekturlesen.korrektorat.list.3'),
      }
    },
    lektorat: {
      heading: TranslationKeys._t('korrekturlesen.lektorat.heading'),
      description: TranslationKeys._t('korrekturlesen.lektorat.description'),
      list: {
        one: TranslationKeys._t('korrekturlesen.lektorat.list.1'),
        two: TranslationKeys._t('korrekturlesen.lektorat.list.2'),
        three: TranslationKeys._t('korrekturlesen.lektorat.list.3'),
        four: TranslationKeys._t('korrekturlesen.lektorat.list.4'),
        five: TranslationKeys._t('korrekturlesen.lektorat.list.5'),
        six: TranslationKeys._t('korrekturlesen.lektorat.list.6'),
      }
    }

  };

  public static readonly fachgebiete = {
    heading: TranslationKeys._t('fachgebiete.heading'),
    description: TranslationKeys._t('fachgebiete.description'),
    eCommerce: {
      heading: TranslationKeys._t('fachgebiete.e-commerce.heading'),
      paragraph1: TranslationKeys._t('fachgebiete.e-commerce.paragraph1'),
      paragraph2: TranslationKeys._t('fachgebiete.e-commerce.paragraph2'),
    },
    IT: {
      heading: TranslationKeys._t('fachgebiete.it.heading'),
      paragraph1: TranslationKeys._t('fachgebiete.it.paragraph1'),
      paragraph2: TranslationKeys._t('fachgebiete.it.paragraph2'),
    },
    music: {
      heading: TranslationKeys._t('fachgebiete.music.heading'),
      paragraph1: TranslationKeys._t('fachgebiete.music.paragraph1'),
      paragraph2: TranslationKeys._t('fachgebiete.music.paragraph2'),
    }
  };

  public static readonly preisgestaltung = {

    heading: TranslationKeys._t('preisgestaltung.heading'),
    translations: {
      heading: TranslationKeys._t('preisgestaltung.translations.heading'),
      description: TranslationKeys._t('preisgestaltung.translations.description'),
      list: {
        one: TranslationKeys._t('preisgestaltung.translations.list.1'),
        two: TranslationKeys._t('preisgestaltung.translations.list.2'),
        three: TranslationKeys._t('preisgestaltung.translations.list.3'),
        four: TranslationKeys._t('preisgestaltung.translations.list.4'),
        five: TranslationKeys._t('preisgestaltung.translations.list.5')
      }
    },
    lektorat: {
      heading: TranslationKeys._t('preisgestaltung.lektorat.heading'),
      description: TranslationKeys._t('preisgestaltung.lektorat.description'),
      list: {
        one: TranslationKeys._t('preisgestaltung.lektorat.list.1'),
        two: TranslationKeys._t('preisgestaltung.lektorat.list.2'),
        three: TranslationKeys._t('preisgestaltung.lektorat.list.3'),
        four: TranslationKeys._t('preisgestaltung.lektorat.list.4')
      }
    }
  };

  public static readonly ueberMich = {

    heading: TranslationKeys._t('ueber-mich.heading'),
    paragraph1: TranslationKeys._t('ueber-mich.paragraph1'),
    paragraph2: {
      beforeInterculturalLink: TranslationKeys._t('ueber-mich.paragraph2.before-intercultural-link'),
      afterInterculturalLink: TranslationKeys._t('ueber-mich.paragraph2.after-intercultural-link'),
      afterRohdeLink: TranslationKeys._t('ueber-mich.paragraph2.after-rohde-link')
    },
    paragraph3: TranslationKeys._t('ueber-mich.paragraph3'),
  };

  public static readonly kontakt = {

    heading: TranslationKeys._t('kontakt.heading'),
    telephone: {
      heading: TranslationKeys._t('kontakt.telephone.heading'),
      description: TranslationKeys._t('kontakt.telephone.description'),
    },
    email: {
      heading: TranslationKeys._t('kontakt.email.heading'),
      description: TranslationKeys._t('kontakt.email.description'),
    }
  };


  public static readonly footer = {
    impressum: TranslationKeys._t('footer.impressum'),
    datenschutz: TranslationKeys._t('footer.datenschutz'),
  };

  public static readonly language = {
    english: TranslationKeys._t('language.english'),
    german: TranslationKeys._t('language.german'),
    french: TranslationKeys._t('language.french')
  };

  public static readonly toastr = {
    newLanguage: TranslationKeys._t('toastr.new-language')
  };

  private static _t(arg): string {
    return arg;
  }
}
