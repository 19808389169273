
<section class="impressum-section">
  <h1>Impressum</h1>

  <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
  <p>Hannah Dobozy<br/>
    Dobozy Übersetzungen<br/>
    Welfenstr. 35<br/>
    76137 Karlsruhe</p>

  <h2>Kontakt</h2>
  <p>
    Telefon: <a href="tel:01715106662">0171 / 510 66 62</a>
    <br/>
    E-Mail: <a href="mailto:kontakt@dobozy-uebersetzungen.de">kontakt@dobozy-uebersetzungen.de</a>
  </p>

  <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
    teilzunehmen.</p>

  <h3>Haftung f&uuml;r Inhalte</h3>
  <p>Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach
    den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch
    nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden
    zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p>
  <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
    hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
    konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
    diese Inhalte umgehend entfernen.</p>
  <h3>Haftung f&uuml;r Links</h3>
  <p>Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
    Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die
    Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
  <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.</p>
  <h3>Urheberrecht</h3>
  <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
    Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb
    der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
    Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.</p>
  <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
    Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

  <h3>Quellen</h3>
  <p>Website-Design: Stefan Bechert</p>
  <p>Photos: Tobias Köhler</p>
  <p>Logo: Franziska Sonnenberg</p>
  <p>Icon - Globe, Computer, Music: <a href="https://www.iconfinder.com/webalys">https://www.iconfinder.com/webalys</a></p>
  <p>Icon - Glasses: <a href=" https://www.iconfinder.com/freeicons.io"> https://www.iconfinder.com/freeicons.io</a></p>
  <p>Icon - Money Box: <a href="https://icons8.com/icons/set/money%20box">https://icons8.com/icons/set/money%20box</a></p>
  <p>Icon - Science: <a href=" https://www.iconfinder.com/winnievinzence"> https://www.iconfinder.com/winnievinzence</a></p>
  <p>Icon - eCommerce: <a href="https://www.iconfinder.com/ALTOP7">https://www.iconfinder.com/ALTOP7</a></p>
  <p>Icon - Mail: <a href="https://www.iconfinder.com/Juliia_Os">https://www.iconfinder.com/Juliia_Os</a></p>
  <p>Impressum: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>

</section>
