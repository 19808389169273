import { Component, OnInit } from '@angular/core';
import { faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {faLinkedin, faXing} from '@fortawesome/free-brands-svg-icons';
import {SupportedLanguageEntry} from '../shared/supported-language-entry';
import {TranslationKeys} from '../shared/translation-keys';
import {TranslationService} from '../services/translation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {

  faXing = faXing;
  faLinkedin = faLinkedin;
  faBars = faBars;
  faChevronDown = faChevronDown;

  supportedLanguageEntries: SupportedLanguageEntry[] = SupportedLanguageEntry.getSupportedLanguageEntries();

  TranslationKeys = TranslationKeys;

  constructor(private translationService: TranslationService, public translateService: TranslateService) {
  }

  changeLanguage(supportedLanguageEntry: SupportedLanguageEntry) {
    this.translationService.changeLanguage(supportedLanguageEntry);
  }
}
