import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainPageComponent} from './main-page/main-page.component';
import {LegalNoticePageComponent} from './legal-notice-page/legal-notice-page.component';
import {PrivacyPolicyPageComponent} from './privacy-policy-page/privacy-policy-page.component';

const routes: Routes = [
  {path: '', component: MainPageComponent},
  {path: 'impressum', component: LegalNoticePageComponent},
  {path: 'datenschutz', component: PrivacyPolicyPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
